import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e66049c = () => interopDefault(import('../pages/GamesLanding/GamesLanding.vue' /* webpackChunkName: "" */))
const _c5cf4150 = () => interopDefault(import('../pages/GamesLanding/WordSearch.vue' /* webpackChunkName: "" */))
const _0f1a4dec = () => interopDefault(import('../pages/Results/Results.vue' /* webpackChunkName: "" */))
const _7cc7a59d = () => interopDefault(import('../pages/app/AppAbout.vue' /* webpackChunkName: "" */))
const _7c5c1285 = () => interopDefault(import('../pages/app/GetTheApp.vue' /* webpackChunkName: "" */))
const _c8aa6e0e = () => interopDefault(import('../pages/app/PrivacyPolicy.vue' /* webpackChunkName: "" */))
const _07f88445 = () => interopDefault(import('../pages/blog/BlogArticlesList/BlogArticlesList.vue' /* webpackChunkName: "" */))
const _26626de2 = () => interopDefault(import('../pages/blog/BlogArticle/BlogArticle.vue' /* webpackChunkName: "" */))
const _3ab0a84f = () => interopDefault(import('../pages/wordle/Hints/HintsPage.vue' /* webpackChunkName: "" */))
const _3aeefde1 = () => interopDefault(import('../pages/wordle/Answers/AnswersPage.vue' /* webpackChunkName: "" */))
const _6de228c6 = () => interopDefault(import('../pages/blog/BlogAuthorBio/BlogAuthorBio.vue' /* webpackChunkName: "" */))
const _8b8bb256 = () => interopDefault(import('../pages/GameCheats.vue' /* webpackChunkName: "" */))
const _50fe6fc6 = () => interopDefault(import('../pages/SingleCmsPage.vue' /* webpackChunkName: "" */))
const _4efd7dfc = () => interopDefault(import('../pages/LTKControlRoom/LTKControlRoom.vue' /* webpackChunkName: "" */))
const _3ace6c1a = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "" */))
const _c81afc88 = () => interopDefault(import('../pages/SpellingBeeLanding/SpellingBeeLanding.vue' /* webpackChunkName: "" */))
const _4b31d52c = () => interopDefault(import('../pages/RandomWordGenerator/RandomWordGenerator.vue' /* webpackChunkName: "" */))
const _6c7959d0 = () => interopDefault(import('../pages/CrosswordSolver/CrosswordSolver.vue' /* webpackChunkName: "" */))
const _0062dcf2 = () => interopDefault(import('../pages/CrosswordSolver/CrosswordPublisher.vue' /* webpackChunkName: "" */))
const _b22f2804 = () => interopDefault(import('../pages/CrosswordSolver/CrosswordClue.vue' /* webpackChunkName: "" */))
const _8e5bed48 = () => interopDefault(import('../pages/Contexto/Contexto.vue' /* webpackChunkName: "" */))
const _6eada5c8 = () => interopDefault(import('../pages/NYTConnections/NYTConnections.vue' /* webpackChunkName: "" */))
const _902a36c8 = () => interopDefault(import('../pages/NYTStrands/NYTStrands.vue' /* webpackChunkName: "" */))
const _0e457d48 = () => interopDefault(import('../pages/NYTConnectionsToday/NYTConnectionsToday.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _5e66049c,
    meta: {"breadcrumbs":null},
    name: "Home"
  }, {
    path: "/4pics1word/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"4 Pics 1 Word Answers","url":""}]},
    name: "home4Pics1Word"
  }, {
    path: "/anagram-solver/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Anagram Solver","url":""}]},
    name: "homeAnagram"
  }, {
    path: "/word-descrambler/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Descrambler","url":""}]},
    name: "homeWordDescrambler"
  }, {
    path: "/wordbrain-answers/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"WordBrain Answers","url":""}]},
    name: "homeWordbrainAnswers"
  }, {
    path: "/text-twist/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Text Twist Solver","url":""}]},
    name: "homeTextTwist"
  }, {
    path: "/scrabble-checker/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Scrabble GO Word Checker","url":""}]},
    name: "homeScrabbleChecker"
  }, {
    path: "/jumble-solver/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Jumble Solver","url":""}]},
    name: "homeJumbleSolver"
  }, {
    path: "/scrabble-dictionary/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Scrabble Dictionary Checker","url":""}]},
    name: "homeWordGame"
  }, {
    path: "/scrabble-cheat/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Scrabble Cheat","url":""}]},
    name: "homeScrabbleCheat"
  }, {
    path: "/word-chums-cheat/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Chums Cheat","url":""}]},
    name: "homeWordChumsCheat"
  }, {
    path: "/unscramble/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Unscrambler","url":""}]},
    name: "homeUnscramble"
  }, {
    path: "/word-cookies/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Cookies Answers","url":""}]},
    name: "homeWordCookies"
  }, {
    path: "/wordfeud-helper/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Wordfeud Cheat","url":""}]},
    name: "homeWordFeud"
  }, {
    path: "/word-maker/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Maker","url":""}]},
    name: "homeWordMaker"
  }, {
    path: "/words-with-friends-cheat/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Words With Friends Cheat","url":""}]},
    name: "homeWordsWithFriends"
  }, {
    path: "/words-with-letters-blank/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Words With Letters and a Blank","url":""}]},
    name: "homeLettersAndBlank"
  }, {
    path: "/word-swipe-cheats/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Swipe Answers","url":""}]},
    name: "homeWordSwipeCheats"
  }, {
    path: "/word-connect-answers/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Connect Answers","url":""}]},
    name: "homeWordConnect"
  }, {
    path: "/codycross-answers/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"CodyCross Answers","url":""}]},
    name: "homeCodyCross"
  }, {
    path: "/words-story-answers/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Words Story Cheat","url":""}]},
    name: "homeWordStory"
  }, {
    path: "/word-scramble/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Scramble","url":""}]},
    name: "homeWordScramble"
  }, {
    path: "/wordle/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Wordle Solver","url":""}]},
    name: "homeWordle"
  }, {
    path: "/quordle/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Quordle Solver","url":""}]},
    name: "homeQuordle"
  }, {
    path: "/index/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"Word Lists","url":""}]},
    name: "homeWordListsIndex"
  }, {
    path: "/word-wars/",
    component: _5e66049c,
    meta: {"breadcrumbs":[{"label":"WordWarsHomeH1","url":""}]},
    name: "homeWordWars"
  }, {
    path: "/word-search/",
    component: _c5cf4150,
    meta: {"breadcrumbs":[{"label":"Word Search"}]},
    name: "wordSearch"
  }, {
    path: "/unscramble/:tiles/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Unscramble","url":"/unscramble/"},{"label":"Results"}]},
    name: "resultsUnscramble"
  }, {
    path: "/scrabble-dictionary/:tiles/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Scrabble Dictionary","url":"/scrabble-dictionary/"},{"label":"Results"}]},
    name: "resultsWordgGame"
  }, {
    path: "/anagram-solver/:tiles/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Anagram Solver","url":"/anagram-solver/"},{"label":"Results"}]},
    name: "resultsAnagram"
  }, {
    path: "/wordle/results/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Wordle Solver","url":"/wordle/"},{"label":"Results"}],"title":"WordleSolverResultsMetaTitle","description":"WordleSolverResultsMetaDescription"},
    name: "resultsWordle"
  }, {
    path: "/quordle/results/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Quordle Solver","url":"/quordle/"},{"label":"Results"}],"title":"QuordleSolverResultsMetaTitle","description":"QuordleSolverResultsMetaDescription"},
    name: "resultsQuordle"
  }, {
    path: "/words-with-the-letter/:advanced(starts-[a-z_]+-with-[a-z_]+|starts-[a-z_]+-ends-[a-z_]+|with-[a-z_]+-ends-[a-z_]+|starts-[a-z_]+-with-[a-z_]+-ends-[a-z_]+)",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words with the letter","url":"/words-with-the-letter/"}]},
    name: "resultsWordsLettersCombination"
  }, {
    path: "/letter-words/:advanced([0-9]+-starts-[a-z_]+|[0-9]+-with-[a-z_]+|[0-9]+-ends-[a-z_]+|[0-9]+-starts-[a-z_]+-with-[a-z_]+|[0-9]+-starts-[a-z_]+-ends-[a-z_]+|[0-9]+-with-[a-z_]+-ends-[a-z_]+|[0-9]+-starts-[a-z_]+-with-[a-z_]+-ends-[a-z_]+)",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words by length","url":"/letter-words/"}]},
    name: "resultsWordsLengthAndLettersCombination"
  }, {
    path: "/words-that-start/",
    component: _5e66049c,
    meta: {"title":"WordListsStartsDistributorPageMetaTitle","description":"WordListsStartsDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words that start with","url":""}]},
    name: "homeWordsStartingWith"
  }, {
    path: "/words-that-start/:letter/",
    component: _0f1a4dec,
    meta: {"title":"WordListsStartsDistributorPageMetaTitle","description":"WordListsStartsDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words that start with","url":"/words-that-start/","isLast":true}]},
    name: "resultsWordsStartingWith"
  }, {
    path: "/words-with-the-letter/",
    component: _5e66049c,
    meta: {"title":"WordListsWithDistributorPageMetaTitle","description":"WordListsWithDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words with the letter","url":""}]},
    name: "homeWordsWith"
  }, {
    path: "/words-with-the-letter/:letter/",
    component: _0f1a4dec,
    meta: {"title":"WordListsWithDistributorPageMetaTitle","description":"WordListsWithDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words with the letter","url":"/words-with-the-letter/","isLast":true}]},
    name: "resultsWordsWith"
  }, {
    path: "/ending-with/",
    component: _5e66049c,
    meta: {"title":"WordListsEndsDistributorPageMetaTitle","description":"WordListsEndsDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words ending in","url":""}]},
    name: "homeWordsEndingWith"
  }, {
    path: "/ending-with/:letter/",
    component: _0f1a4dec,
    meta: {"title":"WordListsEndsDistributorPageMetaTitle","description":"WordListsEndsDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words ending in","url":"/ending-with/","isLast":true}]},
    name: "resultsWordsEndingWith"
  }, {
    path: "/letter-words/",
    component: _5e66049c,
    meta: {"title":"WordListsLengthDistributorPageMetaTitle","description":"WordListsLengthDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words by length","url":""}]},
    name: "homeWordsByLength"
  }, {
    path: "/letter-words/:length/",
    component: _0f1a4dec,
    meta: {"title":"WordListsLengthDistributorPageMetaTitle","description":"WordListsLengthDistributorPageMetaDescription","breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words by length","url":"/letter-words/","isLast":true}]},
    name: "resultsWordsByLength"
  }, {
    path: "/consonants/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Consonants"}]},
    name: "resultsWordsConsonants"
  }, {
    path: "/ending-in-ing/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words Ending in ING"}]},
    name: "resultsWordsEndingING"
  }, {
    path: "/vowels/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Vowels"}]},
    name: "resultsWordsVowels"
  }, {
    path: "/q-no-u/",
    component: _0f1a4dec,
    meta: {"breadcrumbs":[{"label":"Word Lists for Word Games","url":"/index/"},{"label":"Words with Q no U"}]},
    name: "resultsWordsQNoU"
  }, {
    path: "/app/about/",
    component: _7cc7a59d,
    meta: {"title":"AppAboutPageMetaTitle","description":"AppAboutPageMetaDescription","breadcrumbs":[{"label":"About Word Finder"}]},
    name: "aboutApp"
  }, {
    path: "/app/get-the-app/",
    component: _7c5c1285,
    meta: {"title":"GetTheAppPageMetaTitle","description":"GetTheAppPageMetaDescription","breadcrumbs":[{"label":"Get The App"}]},
    name: "getTheApp"
  }, {
    path: "/app/privacy-policy/",
    component: _c8aa6e0e,
    meta: {"title":"LegalInfoPageMetaTitle","description":"LegalInfoPageMetaDescription","breadcrumbs":[{"label":"APP Privacy Policy"}]},
    name: "privacyPolicy"
  }, {
    path: "/blog/:page(\\d+)?/",
    component: _07f88445,
    meta: {"title":"HeaderArticlesIndexMetaTitle","description":"HeaderArticlesIndexMetaDescription","breadcrumbs":[{"label":"BreadcrumbArticlesIndex","url":"/blog/"}]},
    name: "articleIndex"
  }, {
    path: "/blog/:slug/",
    component: _26626de2,
    meta: {"breadcrumbs":[{"label":"BreadcrumbArticlesIndex","url":"/blog/"},{"label":"BreadcrumbArticlePage","url":""}]},
    name: "articleDetail"
  }, {
    path: "/wordle/word-lists/:page(\\d+)?/",
    component: _07f88445,
    meta: {"title":"Wordle Word Lists | WordFinder®","description":"The most complete lists of 5-letter words to win at Wordle. Find all the Wordle words you need and keep your streak alive.","breadcrumbs":[{"label":"Wordle Solver","url":"/wordle/"},{"label":"Word Lists","url":""}]},
    name: "wordleArticlesIndex"
  }, {
    path: "/wordle/word-lists/:slug/",
    component: _26626de2,
    meta: {"breadcrumbs":[{"label":"Wordle Solver","url":"/wordle/"},{"label":"Word Lists","url":"/wordle/word-lists/"},{"label":"Article","url":""}]},
    name: "wordleArticlesDetail"
  }, {
    path: "/wordle/hints/",
    component: _3ab0a84f,
    meta: {"breadcrumbs":[{"label":"Wordle Solver","url":"/wordle/"},{"label":"Wordle Hints"}]},
    name: "wordleHints"
  }, {
    path: "/wordle/answers/",
    component: _3aeefde1,
    meta: {"breadcrumbs":[{"label":"Wordle Solver","url":"/wordle/"},{"label":"Archive of Past Wordle Answers"}]},
    name: "wordleAnswers"
  }, {
    path: "/member/:slug/",
    component: _6de228c6,
    meta: {"breadcrumbs":[{"label":"About Page","url":"/about/"}]},
    name: "authorBio"
  }, {
    path: "/game-cheats/",
    component: _8b8bb256,
    meta: {"title":"GameCheatsPageMetaTitle","description":"GameCheatsPageMetaDescription","breadcrumbs":[{"label":"Game Cheats"}]},
    name: "resultsGameCheats"
  }, {
    path: "/popular-pages/",
    component: _50fe6fc6,
    meta: {"title":"WordFinder Popular Pages | WordFinder","description":"Different word lists serve different purposes for word game enthusiasts. Discover what words the world is looking for with a list of WordFinder's most popular pages.","breadcrumbs":[{"label":"Popular Pages"}]},
    name: "popularPages"
  }, {
    path: "/top-searched-word-combos/",
    component: _50fe6fc6,
    meta: {"title":"WordFinder Top Searched Word Combinations","description":"Sometimes, you just really need a ten letter word as quickly as possible. Travel through the top searched word combinations on WordFinder and discover more.","breadcrumbs":[{"label":"Top Searched Word Combos"}]},
    name: "topSearched"
  }, {
    path: "/top-unscrambles/",
    component: _50fe6fc6,
    meta: {"title":"WordFinder Top Word Unscrambles | WordFinder","description":"From IT to QUARTER, people are unscrambling all kinds of words across the web. See what the world is unscrambling with WordFinder’s top unscrambles.","breadcrumbs":[{"label":"Top Unscrambles"}]},
    name: "topUnscrambles"
  }, {
    path: "/ltk-control-room/",
    component: _4efd7dfc,
    name: "ltk-control-room"
  }, {
    path: "/about/",
    component: _3ace6c1a,
    meta: {"title":"AboutPageMetaTitle","description":"AboutPageMetaDescription","breadcrumbs":[{"label":"About US"}]},
    name: "About"
  }, {
    path: "/nyt-spelling-bee/",
    component: _c81afc88,
    meta: {"breadcrumbs":[{"label":"SpellingBeeAnswersBreadcrumb","url":"/nyt-spelling-bee/"}]},
    name: "nytSpellingBee"
  }, {
    path: "/random-word-generator/",
    component: _4b31d52c,
    meta: {"breadcrumbs":[{"label":"RandomWordGeneratorBreadcrumb","url":"/random-word-generator/"}]},
    name: "randomWordGenerator"
  }, {
    path: "/crosswords/",
    component: _6c7959d0,
    meta: {"title":"CrosswordSolverMetaTitle","description":"CrosswordSolverMetaDescription","breadcrumbs":[{"label":"CrosswordSolverBreadcrumb","url":"/crosswords/"}]},
    name: "crosswordSolver"
  }, {
    path: "/crosswords/:publisher/",
    component: _0062dcf2,
    meta: {"breadcrumbs":[{"label":"CrosswordSolverBreadcrumb","url":"/crosswords/"},{"label":"NYT Crossword Answers","url":"/crosswords/new-york-times-crossword/"}]},
    name: "cluesPublisher"
  }, {
    path: "/crosswords/:publisher/:clue/",
    component: _b22f2804,
    meta: {"breadcrumbs":[{"label":"CrosswordSolverBreadcrumb","url":"/crosswords/"},{"label":"NYT Crossword Answers","url":"/crosswords/new-york-times-crossword/"},{"label":"Clue"}]},
    name: "cluesClue"
  }, {
    path: "/contexto/",
    component: _8e5bed48,
    meta: {"title":"ContextoMetaTitle","description":"ContextoMetaDescription","breadcrumbs":[{"label":"ContextoBreadcrumb","url":"/crosswords/"}]},
    name: "contexto"
  }, {
    path: "/nyt-connections/",
    component: _6eada5c8,
    meta: {"title":"NYT Connections Hints for Today","description":"Unlock daily hints for NYT Connections. Dive into our user-friendly tool and elevate your gameplay. Get started now!","breadcrumbs":[{"label":"NYT Connections Hints & Answers","url":"/nyt-connections/"}]},
    name: "nytConnections"
  }, {
    path: "/nyt-strands/",
    component: _902a36c8,
    meta: {"breadcrumbs":[{"label":"NYT Strands Answers & Hints","url":"/nyt-strands/"}]},
    name: "nytStrands"
  }, {
    path: "/nyt-connections/todays-answers/",
    component: _0e457d48,
    meta: {"title":"NYT Connections Answers Today","description":"Get today's NYT Connections answers and explore our complete archive of past solutions. Stay ahead with daily updates and sharpen your puzzle-solving skills!","breadcrumbs":[{"label":"Today’s NYT Connections Answers","url":"/nyt-connections/todays-answers/"}]},
    name: "nytConnectionsToday"
  }, {
    path: "/app",
    redirect: {"name":"aboutApp"}
  }, {
    path: "/q_no_u",
    redirect: {"name":"resultsWordsQNoU"}
  }, {
    path: "/wordcookies",
    redirect: {"name":"homeWordCookies"}
  }, {
    path: "/unscramble/remember_tiles/:tiles",
    redirect: {"name":"resultsUnscramble"}
  }, {
    path: "/scrabble-dictionary/exact-match/:tiles",
    redirect: {"name":"resultsWordgGame"}
  }, {
    path: "/member/",
    redirect: {"name":"articleIndex"}
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

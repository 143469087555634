
export default {
	data() {
		return {
			playerStarted: false,
			playerInitCalled: false,
			playerInitTimeout: null,
		};
	},
	computed: {
		isExcoScriptLoaded() {
			return this.$store.state.ads.isExcoLoaded;
		},
		excoPlayerId() {
			return process.env.NUXT_ENV_STAGE === "prod"
				? "722bf882-df54-4b64-a54f-b86c95d9e200"
				: "e5d6defa-1679-4434-9a2c-e467f66697f3";
		},
	},
	watch: {
		isExcoScriptLoaded(value) {
			if (value) {
				this.initExcoPlayer();
			}
		},
	},
	mounted() {
		if (!this.playerStarted && !this.playerInitCalled) {
			this.initExcoPlayer();
		}
	},
	beforeDestroy() {
		if (window.ExCoPlayer) {
			const excoAPI = window.ExCoPlayer?.connect?.();
			if (excoAPI) {
				try {
					excoAPI?.destroy?.();
					if (this.isSmallDevice) {
						this.removeExcoEventListeners();
					}
				} catch (error) {
					console.error(
						`Error while trying to destroy Exco ad ${error}`
					);
				}
			}
		}
	},
	destroyed() {
		if (this.playerInitTimeout) {
			clearTimeout(this.playerInitTimeout);
		}
	},
	methods: {
		excoEventCallback(event) {
			if (event.data.type === "exco-event") {
				const { name } = event.data;

				if (name === "player-sticky") {
					this.$store.commit("ads/SET_IS_EXCO_STICKY", true);
				}

				if (name === "player-static") {
					this.$store.commit("ads/SET_IS_EXCO_STICKY", false);
				}
			}
		},
		addEventListeners() {
			window.addEventListener("message", this.excoEventCallback);
		},
		removeExcoEventListeners() {
			window.removeEventListener("message", this.excoEventCallback);
		},
		initExcoPlayer() {
			if (window.ExCoPlayer) {
				const excoAPI = window.ExCoPlayer?.connect?.();
				if (excoAPI) {
					this.playerInitCalled = true;
					this.playerInitTimeout = setTimeout(
						() => {
							if (this.isSmallDevice) {
								this.addEventListeners();
							}
							excoAPI?.init?.();
							this.playerStarted = true;
						},
						this.isSmallDevice ? 5000 : 0
					);
				}
			}
		},
	},
};

/**
 * All page names. Any reference to a page within the app SHOULD always be
 * expressed as `PAGES.WHATEVER`, so please don't use the strings anywhere.
 * It makes the codebase more consistent an readable.
 */
export const PAGES = {
	ANAGRAM: "homeAnagram",
	ANAGRAM_RESULTS: "resultsAnagram",
	ABOUT: "About",
	APP_ABOUT: "aboutApp",
	APP_PRIVACY_POLICY: "privacyPolicy",
	FOURPICS: "home4Pics1Word",
	HOME: "Home",
	JUMBLE_SOLVER: "homeJumbleSolver",
	TEXT_TWIST: "homeTextTwist",
	WORD_SWIPE_CHEATS: "homeWordSwipeCheats",
	DYNAMIC_PAGE: "dynamicPage",
	DYNAMIC_RESULTS_PAGE: "dynamicResultsPage",
	NOT_FOUND: "pageNotFound",
	SCRABBLE_DICT: "homeWordGame",
	SCRABBLE_DICT_RESULTS: "resultsWordgGame",
	UNSCRAMBLE: "homeUnscramble",
	UNSCRAMBLER_RESULTS: "resultsUnscramble",
	WORDCOOKIES: "homeWordCookies",
	WORDFEUD: "homeWordFeud",
	WORDBRAIN_ANSWERS: "homeWordbrainAnswers",
	WORDS_BY_LENGTH_INDEX: "homeWordsByLength",
	WORDS_BY_LENGTH_RESULTS: "resultsWordsByLength",
	WORDS_CONSONANTS: "resultsWordsConsonants",
	WORDS_ENDING_INDEX: "homeWordsEndingWith",
	WORDS_ENDING_ING: "resultsWordsEndingING",
	WORDS_ENDING_RESULTS: "resultsWordsEndingWith",
	WORDS_HAVING_INDEX: "homeWordsWith",
	WORDS_HAVING_RESULTS: "resultsWordsWith",
	WORDS_LISTS_INDEX: "homeWordListsIndex",
	WORDS_STARTING_INDEX: "homeWordsStartingWith",
	WORDS_STARTING_RESULTS: "resultsWordsStartingWith",
	WORDS_VOWELS: "resultsWordsVowels",
	WORDS_WITH_Q_NO_U: "resultsWordsQNoU",
	WORDS_COMBINATION_LETTERS_RESULTS: "resultsWordsLettersCombination",
	WORDS_COMBINATION_LENGTH_AND_LETTERS_RESULTS:
		"resultsWordsLengthAndLettersCombination",
	WORD_MAKER: "homeWordMaker",
	SCRABBLE_CHECKER: "homeScrabbleChecker",
	SCRABBLE_CHEAT: "homeScrabbleCheat",
	WORD_DESCRAMBLER: "homeWordDescrambler",
	WORD_CHUMS_CHEAT: "homeWordChumsCheat",
	LETTERS_AND_BLANK: "homeLettersAndBlank",
	ARTICLE_INDEX: "articleIndex",
	ARTICLE_DETAIL: "articleDetail",
	AUTHOR_BIO: "authorBio",
	WWF: "homeWordsWithFriends",
	WORD_CONNECT: "homeWordConnect",
	GAME_CHEATS: "resultsGameCheats",
	WORD_STORY: "homeWordStory",
	CODY_CROSS: "homeCodyCross",
	WORD_SCRAMBLE: "homeWordScramble",
	GET_THE_APP: "getTheApp",
	SPELLING_BEE_LANDING: "nytSpellingBee",
	RANDOM_WORD_GENERATOR: "randomWordGenerator",
	CROSSWORD_SOLVER: "crosswordSolver",
	CONTEXTO: "contexto",
	WORDLE: "homeWordle",
	WORDLE_RESULTS: "resultsWordle",
	WORDLE_ANSWERS: "wordleAnswers",
	WORDLE_HINTS: "wordleHints",
	WORDLE_ARTICLES_INDEX: "wordleArticlesIndex",
	WORDLE_ARTICLE_DETAIL: "wordleArticlesDetail",
	WORD_SEARCH: "wordSearch",
	QUORDLE: "homeQuordle",
	QUORDLE_RESULTS: "resultsQuordle",
	WORD_WARS: "homeWordWars",
	NYT_CONNECTIONS: "nytConnections",
	NYT_CONNECTIONS_TODAY: "nytConnectionsToday",
	NYT_STRANDS: "nytStrands",
	POPULAR_PAGES: "popularPages",
	TOP_SEARCHED: "topSearched",
	TOP_UNSCRAMBLES: "topUnscrambles",
	CLUES_PUBLISHER: "cluesPublisher",
	CLUES_CLUE: "cluesClue",
};

export const PAGE_GROUPS = {
	LETTERS_PAGE: "lettersPage",
	LENGTH_PAGE: "lengthPage",
	SPECIALS_PAGE: "specialsPage",
	UNSCRAMBLE_PAGE: "unscramblePage",
	SCRABBLE_PAGE: "scrabblePage",
	ANAGRAM_PAGE: "anagramPage",
	WORDLE_PAGE: "wordlePage",
	QUORDLE_PAGE: "quordlePage",
};

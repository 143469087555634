import { PAGES } from "@consts";

/**
 * Only allow approved publishers.
 */
export default ({ route, redirect }) => {
	if (![PAGES.CLUES_PUBLISHER, PAGES.CLUES_CLUE].includes(route.name)) {
		return;
	}

	const publisherSlug = route?.params?.publisher || "";

	const approvedPublisherSlugs = ["new-york-times-crossword"];

	if (!approvedPublisherSlugs.includes(publisherSlug)) {
		return redirect("/crosswords/");
	}
};
